import React from "react";
import { Link, navigate } from "gatsby";

export default () => {
  const BLACK_FRIDAY = 1669284000000; // 2022-11-24 11:00:00
  const TEST = 1669283640000; // 2022-11-24 11:00:00

  return (
    <body className="w-full bg-black flex flex-col h-screen items-center justify-center text-white text-center">
      <p>Jueves, 24 de Noviembre</p>
      <p>11:00 AM</p>

      <div className="mt-6">
        <CountdownTimer targetDate={BLACK_FRIDAY} />
      </div>
    </body>
  );
};

const pageMetadata = {
  name: "Black Friday",
  title: "Black Friday - Dr Planas Cosmetica",
};

export function Head() {
  return (
    <>
      <title>{pageMetadata.title}</title>
      <meta name="description" content="¡Lanzamos la mayor oferta de toda nuestra historia!" />
    </>
  );
}

const useCountdown = (targetDate: number) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = React.useState(countDownDate - new Date().getTime());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  // calculate time left
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [hours, minutes, seconds];
};

const CountdownTimer = ({ targetDate }: { targetDate: number }) => {
  const [hours, minutes, seconds] = useCountdown(targetDate);
  if (hours + minutes + seconds <= 0) {
    if (typeof window !== "undefined") {
      navigate("https://vip.drplanascosmetica.com/productos/pack-antiaging");
    }
    return <ExpiredNotice />;
  } else {
    return <ShowCounter h={hours} m={minutes} s={seconds} />;
  }
};

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Redirigiendo...</span>

      <p className="text-xs mt-14">O pincha en el botón de abajo para acceder a tu oferta</p>
      <Link to="https://vip.drplanascosmetica.com/productos/pack-antiaging">
        <button className="mt-3 px-3 py-1.5 bg-white text-black font-semibold">Acceder</button>
      </Link>
    </div>
  );
};

const ShowCounter = ({ h, m, s }: { h: number; m: number; s: number }) => {
  return (
    <div className="flex">
      <div className="w-20">
        <p>{h}</p>
        <span>{h == 1 ? "Hora" : "Horas"}</span>
      </div>
      <p>:</p>
      <div className="w-20">
        <p>{m}</p>
        <span>{m == 1 ? "Minuto" : "Minutos"}</span>
      </div>
      <p>:</p>
      <div className="w-20">
        <p>{s}</p>
        <span>{s == 1 ? "Segundo" : "Segundos"}</span>
      </div>
    </div>
  );
};
